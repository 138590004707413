import React from 'react';
import PropTypes from 'prop-types';
import M from 'materialize-css';

const classNames = require('classnames');

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.state = { instance: undefined };
  }

  componentDidMount() {
    const options = {
      dismissible: true, // Modal can be dismissed by clicking outside of the modal
      opacity: 0.5, // Opacity of modal background
      inDuration: 300, // Transition in duration
      outDuration: 200, // Transition out duration
      startingTop: '4%', // Starting top style attribute
      endingTop: '10%', // Ending top style attribute
    };
    const elems = document.querySelectorAll(`#${this.props.id}`);
    const instances = M.Modal.init(elems, options);
    this.setState({ instance: instances[0] });
  }

  componentWillReceiveProps(nextProps) {
    const instance = this.state.instance;
    if (instance) {
      if (nextProps.open) {
        instance.open();
      } else if (this.props.open && !nextProps.open) {
        instance.close();
      }
    }
  }

  render() {
    const modalClasses = classNames({
      modal: true,
      'modal-fixed-footer': true,
      openInitially: this.props.open,
      [`${this.props.className}`]: this.props.className
    });

    const buttonClasses = classNames({
      'modal-action': true,
      'waves-effect': true,
      'waves-green': true,
      'btn-flat': true
    });

    return (
      <div id={this.props.id} className={modalClasses}>
        <div className="modal-content">
          { this.props.children }
        </div>
        <div className="modal-footer">
          <a role="button" tabIndex="0" className={buttonClasses} onClick={this.props.onClose}>
            {this.props.buttonText}
          </a>
        </div>
      </div>
    );
  }
}

Modal.propTypes = {
  id: PropTypes.string.isRequired,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

Modal.defaultProps = {
  open: false,
  buttonText: 'Ok',
  className: '',
  children: null
};

export default Modal;
