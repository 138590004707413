import React from 'react';

const SocialMedia = () => {
  const activeLinks = {
    github: 'https://github.com/brycesenz',
    linkedin: 'https://www.linkedin.com/in/brycesenz/',
    gmail: 'bryce.senz@gmail.com',
    gitlab: false,
    facebook: false,
    instagram: false,
    twitter: false
  };

  return (
    <div className="social-media-div">
      {activeLinks.github ?
        (<a href={activeLinks.github} className="icon-button github" target="_blank">
          <i className="fab fa-github"></i>
          <span></span>
        </a>)
        :
        null}

      {activeLinks.linkedin ?
        <a href={activeLinks.linkedin} className="icon-button linkedin" target="_blank">
          <i className="fab fa-linkedin"></i>
          <span></span>
        </a>
        :
        null}

      {activeLinks.gmail ?
        <a href={`mailto:${activeLinks.gmail}`} className="icon-button google" target="_blank">
          <i className="fab fa-google"></i>
          <span></span>
        </a>
        :
        null}

      {activeLinks.gitlab ?
        <a href={activeLinks.gitlab} className="icon-button gitlab" target="_blank">
          <i className="fab fa-gitlab"></i>
          <span></span>
        </a>
        :
        null }

      {activeLinks.facebook ?
        <a href={activeLinks.facebook} className="icon-button facebook" target="_blank">
          <i className="fab fa-facebook-f"></i>
          <span></span>
        </a>
        :
        null}

      {activeLinks.instagram ?
        <a href={activeLinks.instagram} className="icon-button instagram" target="_blank">
          <i className="fab fa-instagram"></i>
          <span></span>
        </a>
        :
        null}

      {activeLinks.twitter ?
        <a href={activeLinks.twitter} className="icon-button twitter" target="_blank">
          <i className="fab fa-twitter"></i>
          <span></span>
        </a>
        :
        null}

    </div>
  );
};

export default SocialMedia;
