import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';

import Modal from '../../lib/layout/Modal';
import Homepage from '../Homepage/Homepage';
import Credda from '../Credda/Credda';

import { userSelectors } from '../../modules/user/index';
import { modalOperations } from '../../modules/modal/index';
import { getContainerStyles} from './lib/styles';
import { useUrlCredentials } from './lib/use-url-credentials';
import * as shims from './lib/shims';

const App = ({location}) => {
  useUrlCredentials(location.search);

  const dispatch = useDispatch();
  // const authenticated = useSelector((state) => userSelectors.authenticatedSelector(state));
  const hasModalMessage = useSelector(state => state.modal.active);
  const modalMessage = useSelector(state => state.modal.message);
  const handleModalClose = () => dispatch(modalOperations.dismissModal());

  let modalArea = <div className="no-modal"></div>;
  if (hasModalMessage) {
    modalArea = (
      <Modal id="authentication-modal" open={hasModalMessage} onClose={handleModalClose}>
        { modalMessage }
      </Modal>
    );
  }
  return (
    <div id="app">
      {modalArea}
      <div className={getContainerStyles(location)}>
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/home" component={Homepage} />
          {/*<Route exact path="/credda" component={Credda} />*/}
          <Redirect to="/" />
        </Switch>
      </div>
    </div>
  );
};

export default withRouter(App);
