import React from 'react';
import { Fade } from 'react-reveal';
import emoji from 'react-easy-emoji';

const Footer = () => {
  return (
    <Fade bottom duration={1000} distance="5px">
      <div className="footer-div">
        <p className="footer-text">{emoji("Made with ❤️")}</p>
        {/*<p className="footer-text">Credit to Saad for the theme</p>*/}
      </div>
    </Fade>
  );
};

export default Footer;
