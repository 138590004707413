import React from 'react';
import Header from '../../lib/layout/Header';
import Greeting from '../../lib/components/Greeting';
import Skills from '../../lib/components/Skills';
import SkillsChart from '../../lib/components/SkillsChart';
import WorkExperience from '../../lib/components/WorkExperience';
import Projects from '../../lib/components/Projects';
import ContactMe from '../../lib/components/ContactMe';

// import Projects from "./projects/Projects";
// import StartupProject from "./StartupProjects/StartupProject";
// import Achievement from "./achievement/Achievement";
// import Blogs from "./blogs/Blogs";
// import Contact from "./contact/Contact";
// import Footer from "../components/footer/Footer";
// import Talks from "./talks/Talks";
// import Podcast from "./podcast/Podcast";
// import Top from "./topbutton/Top";
// import Twitter from "./twitter-embed/twitter";
import Profile from '../../lib/components/Profile';

import Footer from '../../lib/components/Footer';
import Top from '../../lib/components/Top';

const Homepage = () => {
  return (
      <div>
        <Header />
        <Greeting />
        <Skills />
        <SkillsChart />
        <WorkExperience />
        <Projects />
        {/*<StartupProject />*/}
        {/*<Achievement />*/}
        {/*<Blogs />*/}
        {/*<Talks />*/}
        {/*<Twitter />*/}
        {/*<Podcast />*/}
        <Profile />
        {/*<ContactMe />*/}
        <Footer />
        <Top />
      </div>
  );
}

export default Homepage;
