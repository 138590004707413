import React from 'react';
import { Fade } from 'react-reveal';

// import whiteboard from '../../../assets/images/skill.svg';
import whiteboard from '../../../assets/images/whiteboard.svg';

const SkillsChart = () => {
  const experience = [
    {
      stack: "Backend & API Architecture",
      progressPercentage: "95%"
    },
    {
      stack: "Project Management & Agile",
      progressPercentage: "75%"
    },
    {
      stack: "Frontend & Design",
      progressPercentage: "60%"
    }
  ];

  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="skills-container">

        <div className="skills-bar">
          <h1 className="skills-heading">Proficiency</h1>
          {experience.map((exp, index) => {
            const progressStyle = {
              width: exp.progressPercentage
            };
            return (
              <div key={index} className="skill">
                <p>{exp.stack}</p>
                <div className="meter">
                  <span style={progressStyle}></span>
                </div>
              </div>
            );
          })}
        </div>

        <div className="skills-image">
          <img alt="Skills" src={whiteboard} />
        </div>
      </div>
    </Fade>
  );

  return null;
};

export default SkillsChart;
