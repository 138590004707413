import React from 'react';
import { Fade } from 'react-reveal';
import { HashLink as Link } from 'react-router-hash-link';

const Header = () => {
  return (
    <Fade top duration={1000} distance="20px">
      <div>
        <header className="header">
          <Link to="/" className="logo">
            <span className="grey-color"> &lt;</span>
            <span className="logo-name">Bryce Senz</span>
            <span className="grey-color">/&gt;</span>
          </Link>
          <input className="menu-btn" type="checkbox" id="menu-btn" />
          <label className="menu-icon" htmlFor="menu-btn">
            <span className="navicon"></span>
          </label>
          <ul className="menu">
            <li>
              <Link to="/#skills">Skills</Link>
            </li>
            <li>
              <Link to="/#experience">Work Experiences</Link>
            </li>
            <li>
              <Link to="/#opensource">Open Source</Link>
            </li>
            {/*<li>*/}
              {/*<Link to="/#projects">Projects</Link>*/}
            {/*</li>*/}
            {/*<li>*/}
              {/*<Link to="/#achievements">Achievements</Link>*/}
            {/*</li>*/}
            <li>
              <Link to="/#contact">Contact Me</Link>
            </li>
          </ul>
        </header>
      </div>
    </Fade>
  );
};

export default Header;
