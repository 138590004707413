import React from 'react';
import SocialMedia from './SocialMedia';
import { Fade } from 'react-reveal';

import SoftwareLogo from '../../../assets/images/software-logo.png';

const ContactMe = () => {
  return (
    <Fade bottom duration={1000} distance="20px">
      <div className="main contact-margin-top" id="contact">
        <div className="contact-div-main">
          <div className="contact-header">
            <h1 className="heading contact-title">Drop me a line</h1>
            <p className="subTitle contact-subtitle">I'm always happy to discuss new projects and opportunities</p>

            <div className="contact-text-div">
              {/*<a className="contact-detail" href={"tel:" + contactInfo.number}>{contactInfo.number}</a>*/}
              {/*<br/><br/>*/}
              <a className="contact-detail-email" href={"mailto:bryce.senz@gmail.com"}>bryce.senz@gmail.com</a>
              <br/><br/>
              <SocialMedia/>
            </div>
          </div>
          <div className="contact-image-div">
            {/*<img alt="Bryce Working" src={SoftwareLogo}></img>*/}
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default ContactMe;
