import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import App from '../containers/App/App';
import store from './store';

console.log('Loading BryceSenz client side application...');

ReactDOM.render((
  <div id="application-root">
    <Provider store={store}>
      <Router>
        <App />
      </Router>
    </Provider>
  </div>
), document.getElementById('root'));
