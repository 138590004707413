import React, { useState, useEffect, Suspense } from 'react';
import ApolloClient, { gql } from 'apollo-boost';

import ContactMe from './ContactMe';
import Loading from './Loading';

const renderLoader = () => <Loading />;
import GithubProfileCard from './GithubProfileCard';

const Profile = () => {
  const [prof, setrepo] = useState([]);
  let showProfile = true;

  function setProfileFunction(array) {
    setrepo(array);
  };

  function getProfileData() {
    const client = new ApolloClient({
      uri: "https://api.github.com/graphql",
      request: (operation) => {
        operation.setContext({
          headers: {
            authorization: `Bearer ${process.env.REACT_APP_GITHUB_TOKEN}`,
          },
        });
      },
    });

    client
      .query({
        query: gql`
      {
        user(login: "brycesenz") { 
          name
          bio
          isHireable
          avatarUrl
          location
        }
    }
      `,
      })
      .then((result) => {
        setProfileFunction(result.data.user);
      })
      .catch(function (error) {
        console.log(error);
        setProfileFunction("Error");
        console.log("Because of this Error Contact Section is Showed instead of Profile");
        showProfile = false;
      });
  }

  useEffect(() => {
    getProfileData();
  }, []);

  if (showProfile) {
    return (
      <Suspense fallback={renderLoader()}>
        <GithubProfileCard prof={prof} key={prof.id} />
      </Suspense>
    );
  } else {
    return <ContactMe />;
  }
};

export default Profile;
