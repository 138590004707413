import * as CONSTANTS from '../../utils/constants';
import * as types from './types';

export const authorizeUser = (body) => ( {
  endpoint: `${CONSTANTS.AUTH_URL}/sign_in`,
  method: 'POST',
  body: body,
  startAction: types.AUTHENTICATION_START,
  successAction: types.AUTHENTICATION_COMPLETE,
  failAction: types.AUTHENTICATION_ERROR
} );

export const logOut = () => ( {
  endpoint: `${CONSTANTS.AUTH_URL}/sign_out`,
  method: 'DELETE',
  body: {},
  startAction: types.CLEAR_AUTHENTICATION,
  successAction: types.AUTHENTICATION_CLEARED,
  failAction: types.CLEAR_AUTHENTICATION_FAILED
} );

export const timeOut = () => ( {
  type: types.AUTHENTICATION_CLEARED
} );

export const resetPassword = (body) => ( {
  endpoint: `${CONSTANTS.AUTH_URL}/password`,
  method: 'POST',
  body: { ...body, redirect_url: `${CONSTANTS.HOST}/user/password/edit` },
  startAction: types.PASSWORD_RESET_STARTED,
  successAction: types.PASSWORD_RESET_SUBMITTED,
  failAction: types.PASSWORD_RESET_FAILED
} );

export const changePassword = (body) => ( {
  endpoint: `${CONSTANTS.AUTH_URL}/password`,
  method: 'PUT',
  body: body,
  startAction: types.PASSWORD_CHANGE_STARTED,
  successAction: types.PASSWORD_CHANGE_SUBMITTED,
  failAction: types.PASSWORD_CHANGE_FAILED
} );

export const requestUnlock = (body) => ( {
  endpoint: `${CONSTANTS.AUTH_URL}/unlock`,
  method: 'POST',
  body: body,
  startAction: types.UNLOCK_REQUEST_STARTED,
  successAction: types.UNLOCK_REQUEST_SUBMITTED,
  failAction: types.UNLOCK_REQUEST_FAILED,
} );

export const updateAccount = (body) => ( {
  endpoint: `${CONSTANTS.AUTH_URL}`,
  method: 'PUT',
  body: body,
  startAction: types.ACCOUNT_UPDATE_STARTED,
  successAction: types.ACCOUNT_UPDATE_SUBMITTED,
  failAction: types.ACCOUNT_UPDATE_FAILED,
} );
