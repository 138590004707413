import * as types from './types';

const INITIAL_STATE = {
  active: false,
  message: undefined
};

/* State shape
  {
    active: bool,
    message: object
  }
*/

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_FLASH:
      return {
        active: true,
        message: action.message
      };
    case types.DISMISS_FLASH:
      return {
        active: false,
        message: undefined
      };
    default:
      return state;
  }
}
