import { RSAA } from 'redux-api-middleware'; // RSAA = '@@redux-api-middleware/RSAA'
import { push } from 'react-router-redux';
import _ from 'lodash';

import * as caseTransform from '../../utils/case-transformations';
import {
  getAuthHeaders,
  updateAuthCredentials,
  hasStoredAuth,
  clearStoredVars,
  storeUserDetails
} from '../../utils/jwt-helpers';
import * as actions from '../../modules/user/actions';
import { modalOperations } from '../../modules/modal';

const callBackendApi = (actionDef) => {
  const {
    endpoint,
    method,
    body,
    meta,
    startAction,
    successAction,
    failAction
  } = actionDef;

  const rsaaAction = {
    [RSAA]: {
      endpoint,
      headers: getAuthHeaders(),
      method,
      body: JSON.stringify(caseTransform.toSnakeCase(body)),
      types: [
        {
          type: startAction,
          meta
        },
        {
          type: successAction,
          meta,
          payload: (action, state, res) => {
            // Make sure to update our JWT credentials!
            updateAuthCredentials(res);
            if (res.status === 204) {
              return res.json();
            }
            return res.json().then((json) => {
              return caseTransform.toCamelCase(json);
            });
          }
        },
        {
          type: failAction,
          meta,
          payload: (action, state, res) => {
            // Make sure to update our JWT credentials!
            updateAuthCredentials(res);
            if (res.status === 401 && hasStoredAuth()) {
              // This is just in case for some reason our token auth fails
              // In that event, we clear out our stored credentials
              clearStoredVars();
            }
            return res.json().then((json) => { return caseTransform.toCamelCase(json); });
          }
        }
      ]
    }
  };

  return rsaaAction;
};

const modalResponseHandler = (dispatch, response, successMessage) => {
  if (response.error) {
    const { payload } = response;
    const errors = _.get(payload, 'errors', 'An error has occurred.');
    dispatch(modalOperations.setModal(errors));
  } else if (successMessage) {
    dispatch(modalOperations.setModal(successMessage));
  }
};

export {
  callBackendApi,
  modalResponseHandler
};
