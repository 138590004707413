import reducer from './reducers';

import * as permissionsOperations from './operations';
import * as permissionSelectors from './selectors';

export {
  permissionsOperations,
  permissionSelectors
};

export default reducer;
