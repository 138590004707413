const prefix = `user`;

// Signing In
export const AUTHENTICATION_START             = `${prefix}/AUTHENTICATION_START`;
export const AUTHENTICATION_COMPLETE          = `${prefix}/AUTHENTICATION_COMPLETE`;
export const AUTHENTICATION_ERROR             = `${prefix}/AUTHENTICATION_ERROR`;

// Signing Out
export const CLEAR_AUTHENTICATION             = `${prefix}/CLEAR_AUTHENTICATION`;
export const AUTHENTICATION_CLEARED           = `${prefix}/AUTHENTICATION_CLEARED`;
export const CLEAR_AUTHENTICATION_FAILED      = `${prefix}/CLEAR_AUTHENTICATION_FAILED`;

// Password Resetting
export const PASSWORD_RESET_STARTED           = `${prefix}/PASSWORD_RESET_STARTED`;
export const PASSWORD_RESET_SUBMITTED         = `${prefix}/PASSWORD_RESET_SUBMITTED`;
export const PASSWORD_RESET_FAILED            = `${prefix}/PASSWORD_RESET_FAILED`;

// Password Resetting
export const PASSWORD_CHANGE_STARTED          = `${prefix}/PASSWORD_CHANGE_STARTED`;
export const PASSWORD_CHANGE_SUBMITTED        = `${prefix}/PASSWORD_CHANGE_SUBMITTED`;
export const PASSWORD_CHANGE_FAILED           = `${prefix}/PASSWORD_CHANGE_FAILED`;

// Unlocking Account
export const UNLOCK_REQUEST_STARTED           = `${prefix}/UNLOCK_REQUEST_STARTED`;
export const UNLOCK_REQUEST_SUBMITTED         = `${prefix}/UNLOCK_REQUEST_SUBMITTED`;
export const UNLOCK_REQUEST_FAILED            = `${prefix}/UNLOCK_REQUEST_FAILED`;

// Updating Account
export const ACCOUNT_UPDATE_STARTED           = `${prefix}/ACCOUNT_UPDATE_STARTED`;
export const ACCOUNT_UPDATE_SUBMITTED         = `${prefix}/ACCOUNT_UPDATE_SUBMITTED`;
export const ACCOUNT_UPDATE_FAILED            = `${prefix}/ACCOUNT_UPDATE_FAILED`;
