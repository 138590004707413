function computeHost(rawHost) {
  const herokuRegex = /herokuapp/;
  const httpsRegex = /https/;

  if (rawHost.match(herokuRegex) || (window.location.protocol.match(httpsRegex))) {
    return `https://${rawHost}`;
  }
  return `http://${rawHost}`;
}

let rawHost = window.location.host;
if (window.location.host === 'localhost') {
  // Used for testing
  rawHost = 'www.example.com';
}

export const HOST               = computeHost(rawHost);
export const AUTH_URL           = `${HOST}/api/v1`;
// TODO: This shouldn't have to be *nearly* this long
export const TIMEOUT_MS         = 40000;
