import * as types from './types';
import * as userTypes from '../user/types';

/* State shape
  {
    roles: object,
    permissions: object
  }
*/

export default function (state = {}, action) {
  switch (action.type) {
    case types.GET_PERMISSIONS_START:
      return {
        roles: undefined,
        permissions: undefined
      };
    case types.GET_PERMISSIONS_COMPLETE:
      return {
        roles: action.payload.roles,
        permissions: action.payload.permissions,
      };
    case types.GET_PERMISSIONS_ERROR:
      return {
        roles: undefined,
        permissions: undefined
      };
    case userTypes.AUTHENTICATION_CLEARED:
      return {
        roles: undefined,
        permissions: undefined
      };
    default:
      return state;
  }
}
