import reducer from './reducers';
import * as userOperations from './operations';
import * as userSelectors from './selectors';

export {
  userOperations,
  userSelectors
};

export default reducer;
