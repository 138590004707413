import React from 'react';
import { Fade } from 'react-reveal';
import emoji from 'react-easy-emoji';

import SocialMedia from './SocialMedia';
import Button from './Button';
// import greetingImage from '../../../assets/images/manOnTable.svg';
import greetingImage from '../../../assets/images/manWorkingWithWindow.svg';

const Greeting = () => {
  const greetingText = "Build great tech";
  const subtitle = emoji("I'm Bryce - an engineering leader with experience architecting web applications and growing engineering teams for innovative companies. Let's connect.");
  const resumeLink = "/resume.pdf";

  return (
    <Fade bottom duration={1000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <div>
              <h1 className="greeting-text">
                {" "}
                {greetingText}
                {" "}
                <span className="wave-emoji">{emoji("🔧")}</span>
              </h1>
              <p className="greeting-text-p subTitle">{subtitle}</p>
              <SocialMedia />
              <div className="button-greeting-div">
                <Button text="Contact me" href="mailto:bryce.senz@gmail.com" />
                <Button text="See my resume" newTab={true} href={resumeLink} />
              </div>
            </div>
          </div>
          <div className="greeting-image-div">
            <img alt="bryce profile" src={greetingImage}></img>
          </div>
        </div>
      </div>
    </Fade>
  );
};

export default Greeting;
