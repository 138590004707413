// https://github.com/reactjs/reselect/issues/97

import _ from 'lodash';
import { createSelector } from 'reselect';

export const userSelector = (state) => {
  if (state.user) {
    return state.user.user;
  }
  return undefined;
};

export const userAgreementSelector = (state) => {
  return state.user.userAgreement;
};

export const authenticatedSelector = (state) => {
  if (state.user) {
    return Boolean(state.user.authenticated);
  } else {
    return false;
  }
};

export const userAgreementExpiredSelector = createSelector(
    userAgreementSelector,
    (agreement) => {
      if (agreement) {
        return (agreement.status === 'unsigned');
      }
      return false;
    }
);
