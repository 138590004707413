import React from 'react';
import { Fade } from 'react-reveal';
import emoji from 'react-easy-emoji';

import SoftwareSkill from './SoftwareSkill';
// import skillsImage from '../../../assets/images/developerActivity.svg';
import skillsImage from '../../../assets/images/discussion.svg';

const Skills = () => {
  const title = "About me";
  const subTitle = "PRODUCT ARCHITECT AND DEVELOPER WITH RICH STARTUP EXPERIENCE";
  const skills = [
    emoji("⚡ Build and lead high performing engineering teams"),
    emoji("⚡ Deep knowledge of API-first development and microservice architectures"),
    emoji("⚡ Agile process and product management skills")
  ];

  return (
    <div className="main" id="skills">
      <div className="skills-main-div">
        <Fade left duration={1000}>
          <div className="skills-image">
            <img alt="Bryce Working" src={skillsImage}></img>
          </div>
        </Fade>
        <Fade right duration={1000}>
          <div className="skills-text-div">
            <h1 className="skills-heading">{title} </h1>
            <p className="subTitle skills-text-subtitle">{subTitle}</p>
            <SoftwareSkill />
            <div>
              {skills.map((skill, index) => {
                return <p key={index} className="subTitle skills-text">{skill}</p>;
              })}
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Skills;
