import * as types from './types';

const INITIAL_STATE = {
  active: false,
  message: undefined
};

/* State shape
  {
    active: bool,
    message: object
  }
*/

function messageToString(message) {
  if (Array.isArray(message)) {
    return message.join(', ');
  }
  return message;
}

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.SET_MODAL:
      return {
        active: true,
        message: messageToString(action.message)
      };
    case types.DISMISS_MODAL:
      return {
        active: false,
        message: undefined
      };
    default:
      return state;
  }
}
