import { createStore, compose, applyMiddleware, combineReducers } from 'redux';
// import { routerReducer, routerMiddleware } from 'react-router-redux';
import { apiMiddleware } from 'redux-api-middleware';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import thunk from 'redux-thunk';
import * as reducers from '../modules';

let middlewares = [];
middlewares.push(thunk);
middlewares.push(apiMiddleware);
// middlewares.push(routerMiddleware(history));
if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  // middlewares.push(logger);
}

const reducer = combineReducers({
  ...reducers,
});

// The reducer is our object above which returns the new state.
// redux.compose() lets us add different middleware
//  Here we're adding in the Chrome Redux DevTools middleware
/* eslint-disable no-underscore-dangle */
const store = createStore(reducer, compose(
  composeWithDevTools(
    applyMiddleware(...middlewares)
  )
));
/* eslint-enable */

export default store;
