import React from 'react';
import { Fade } from 'react-reveal';

import ExperienceCard from './ExperienceCard';
import ascertisLogo from '../../../assets/images/ascertisSolutionsLogo.png';
import creddaLogo from '../../../assets/images/creddaLogo.png';
import hiringThingLogo from '../../../assets/images/hiringThingLogo.png';

const WorkExperience = () => {
  const experiences = [
    {
      role: "CTO",
      company: "Credda Capital",
      companylogo: creddaLogo,
      date: "2012 – 2017",
      desc: "Point-of-Sale Leasing & Origination Platform",
      descBullets: [
        "Developed the first fully-paperless application and funding process in the retail lease-to-purchase industry",
        "Scaled the business from a small start-up to a managed team of both on-shore and off-shore engineers"
      ]
    },
    {
      role: "Director of Integrations",
      company: "HiringThing",
      companylogo: hiringThingLogo,
      date: "2017 – Present",
      desc: "API-driven Applicant Tracking Software",
      descBullets: [
        "Built an engineering division to enable the transformation of legacy code base into an API driven product",
        "Managed integration partner relations & developed engineering patterns and practices to allow rapid onboarding of new integrated services"
      ]
    },
    {
      role: "CTO",
      company: "Ascertis Solutions",
      companylogo: ascertisLogo,
      backgroundColor: 'rgb(27, 69, 104)',
      date: "2018 – Present",
      desc: "Platform for NIST 800-171 & CMMC security standard accreditation",
      descBullets: [
        "First in market with a web application specifically for the NIST 800-171 & CMMC cybersecurity standard",
        "Reduces the time to complete a cybersecurity assessment by over 70%"
      ]
    }
  ];

  return (
    <div id="experience">
      <Fade bottom duration={1000} distance="20px">
        <div className="experience-container" id="workExperience">
          <div>
            <h1 className="experience-heading">Experiences</h1>
            <div className="experience-cards-div">
              {experiences.map((experience, index) => {
                return (
                  <ExperienceCard
                    key={index}
                    cardInfo={{
                      company: experience.company,
                      desc: experience.desc,
                      date: experience.date,
                      companylogo: experience.companylogo,
                      backgroundColor: experience.backgroundColor,
                      role: experience.role,
                      descBullets: experience.descBullets
                    }}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </Fade>
    </div>
  );

  return null;
};

export default WorkExperience;
