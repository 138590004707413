import { getAuthState } from '../../utils/jwt-helpers';
import * as types from './types';

const INITIAL_STATE = getAuthState();

/* State shape
  {
    endpoint: string,
    pending: bool,
    error: bool,
    user: object,
    userAgreement: object
  }
*/

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.AUTHENTICATION_START:
      return {
        ...state,
        pending: true,
        authenticated: false,
        user: undefined
      };
    case types.AUTHENTICATION_COMPLETE:
      return {
        ...state,
        pending: false,
        authenticated: true,
        user: action.payload.data
      };
    case types.AUTHENTICATION_ERROR:
      return {
        ...state,
        authenticated: false,
        user: undefined,
        pending: false
      };
    case types.CLEAR_AUTHENTICATION:
      return {
        ...state,
        pending: true,
        authenticated: false,
        user: undefined
      };
    case types.AUTHENTICATION_CLEARED:
      return {
        ...state,
        pending: false,
        authenticated: false,
        user: undefined
      };
    case types.CLEAR_AUTHENTICATION_FAILED:
      return {
        ...state,
        pending: false,
        authenticated: false,
        user: undefined
      };

    // Password Changes
    case types.PASSWORD_RESET_STARTED:
      return { ...state, pending: true };
    case types.PASSWORD_RESET_SUBMITTED:
      return { ...state, pending: false };
    case types.PASSWORD_RESET_FAILED:
      return { ...state, pending: false };

    // Password Changes
    case types.PASSWORD_CHANGE_STARTED:
      return { ...state, pending: true };
    case types.PASSWORD_CHANGE_SUBMITTED:
      return { ...state, pending: false };
    case types.PASSWORD_CHANGE_FAILED:
      return { ...state, pending: false };

    // Account Unlocks
    case types.UNLOCK_REQUEST_STARTED:
      return { ...state, pending: true };
    case types.UNLOCK_REQUEST_SUBMITTED:
      return { ...state, pending: false };
    case types.UNLOCK_REQUEST_FAILED:
      return { ...state, pending: false };

    // Account Updates
    case types.ACCOUNT_UPDATE_STARTED:
      return { ...state, pending: true };
    case types.ACCOUNT_UPDATE_SUBMITTED:
      return { ...state, pending: false };
    case types.ACCOUNT_UPDATE_FAILED:
      return { ...state, pending: false };

    default:
      return state;
  }
}
