import React from 'react';
// import { skillsSection } from "../../portfolio";

const SoftwareSkill = () => {
  const skillsDescriptions = [
    {
      skillName: "ruby",
      fontAwesomeClassname: "fa fa-gem"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "html-5",
      fontAwesomeClassname: "fab fa-html5"
    },
    {
      skillName: "css3",
      fontAwesomeClassname: "fab fa-css3-alt"
    },
    {
      skillName: "npm",
      fontAwesomeClassname: "fab fa-npm"
    },
    {
      skillName: "sql",
      fontAwesomeClassname: "fa fa-database"
    },
    {
      skillName: "aws",
      fontAwesomeClassname: "fab fa-aws"
    },
    {
      skillName: "python",
      fontAwesomeClassname: "fab fa-python"
    },
  ]

  return (
    <div>
      <div className="software-skills-main-div">
        <ul className="dev-icons">
          {skillsDescriptions.map((skill, index) => {
            return (
              <li key={index} className="software-skill-inline" name={skill.skillName}>
                <i className={skill.fontAwesomeClassname}></i>
                <p>{skill.skillName}</p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default SoftwareSkill;
